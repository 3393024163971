@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-slate-100 m-0;
}

[data-hidden] {
  @apply hidden;
}